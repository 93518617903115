ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}
/*.main-content{
  --background: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
}*/
.lefmenusec
{

   --background: #e9f0f9 !important;
  --background: -webkit-gradient(linear, left top, right top, from(#0072ff), to(#00d4ff));
}

/*.login-page-content{
--background: linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100%);
}
.item-input,.item-select{
   --background: #e9f0f9 !important;
}
.list-md{
 --background: #e9f0f9 !important;

}*/
.dashboard-first{
background: #81d0f7;
color: white !important;
}
.dashboard-second{
background: #fa919f;
color: white !important;
}
ion-col {
  /*background-color: #135d54;
  border: solid 1px #fff;
  color: #fff;
  text-align: center;*/
}
#UserTable_wrapper{
  overflow-x: auto !important;
}
.table-responsive
{
  overflow-x: auto !important;
}
.404image{
  margin-top: 20%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}
:host {
    --border: 1pxsolidvar(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
    --side-min-width: 236px;
   /* --side-max-width: 25% !important;*/
   max-width: 20% !important;
}


host{
width: 20% !important;
}
.loanTable {
  width: 99.50%;
  border-collapse: collapse;
  margin-left: 0.50%;
}
table, td, th {
  border: 1px solid #ddd !important;

}
.loanTable tr th,.loanTable tr td
{
  padding: 1%;
}
.loanTable tr th{
  width: 7% !important;
  text-align: left !important;
}
/*@media screen and (min-width: 1024px) {
 ion-menu
{
  width: 20% !important;
}
}*/

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}
.inner-scroll{
  max-width: 20% !important;
}
.menu-side-start{
  /*width: 20% !important;*/
  /*max-width: 20% !important;*/
}
ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}
.passwordSec{
  display: block !important;
}
.loan_amount
{
  font-size: 30px !important;
    font-weight: 700 !important;
}
ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

.menu-pane-visible{
  max-width: 20% !important;
}
ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
} 