.loginImage{
	background: red !important;
}
.loginSec
{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 13%;
}

.btnColor{
	font-family: Helvetica;
    background-color: rgb(255, 77, 79) !important;
    color: white;
    text-transform: capitalize !important;
    border-radius: 2px;
}

.loginImage
{
	margin: 0 auto !important;
}