ion-card-title{
	font-size: 18px !important;
}
.show-modal{
	--width: 100% !important;
    --min-width: auto !important;
    --max-width: auto !important;
}
.css-2iqo30-MuiStack-root{
	margin-left: 3% !important;
}
.loan_found{
	
}
:host(.button-small) {
    float: right !important;
}